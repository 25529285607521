import { useRef, useEffect, useState } from 'react';
import Camera from './Camera';
import { createPostcard } from '../../utils/WebService';
import styled from 'styled-components';

const PhotoContainer = styled.div`
  width: 100%;
  height: 100vh;
`;

const BaseButton = styled.p`
  position: absolute;
  bottom: 50px;
  right: 10px;
  width: 150px;
  height: 50px;
  color: white;
  border-radius: 5px;
  display: grid;
  justify-content: center;
  align-items: center;
`;

const PhotoButton = styled(BaseButton)`
  background: #1aa162;
`;

const LoadingButton = styled(BaseButton)`
  background: #1e8df5;
`;

function Photo() {
  const [sending, setSending] = useState(false);
  const cameraRef = useRef(null); 
  const time = 60000 * 3; 

  useEffect(() => {
    const takePicture = async () => {
      if (sending) return;

      setSending(true);
      const image = await cameraRef.current.takePicture(); 
      await createPostcard(image);
      setSending(false);
    };

    const interval = setInterval(() => {
      takePicture();
    }, time);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [sending, time]);

  const onAppClickHandler = async () => {

    

    if (!sending) {
      console.log('sebnd')

      setSending(true);
      const image = await cameraRef.current.takePicture(); 
      await createPostcard(image);
      setSending(false);


    }
  };

  const getButton = () => {
    if (!sending)
      return <PhotoButton onClick={onAppClickHandler}>TAKE A PHOTO</PhotoButton>;
    else
      return <LoadingButton>LOADING</LoadingButton>;
  };

  return (
    <PhotoContainer>
      {getButton()}
      <Camera ref={cameraRef}></Camera> {/* Properly passing ref */}
    </PhotoContainer>
  );
}

export default Photo;
