const NoiseShader = {
  uniforms: {
    'tDiffuse': { value: null }, // Texture from the previous pass
    'amount': { value: 0.1 },   // Control noise amount
    'time': { value: 0.0 }       // Time uniform for dynamic noise
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    varying vec2 vUv;
    uniform sampler2D tDiffuse;  // Texture from previous pass
    uniform float amount;        // Noise amount
    uniform float time;          // Time uniform to animate noise

    // Simple random function with time component to generate dynamic noise
    float rand(vec2 co, float t) {
      return fract(sin(dot(co.xy + t, vec2(12.9898, 78.233))) * 43758.5453);
    }

    void main() {
      vec4 color = texture2D(tDiffuse, vUv);
      float noise = rand(vUv, time) * amount;
      gl_FragColor = vec4(color.rgb + noise, color.a);
    }
  `
};

export default NoiseShader;
