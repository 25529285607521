import * as THREE from 'three';
import PaperFlocking from './PaperFlocking';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { VignetteShader } from 'three/examples/jsm/shaders/VignetteShader.js';

import NoiseShader from '../shaders/NoiseShader';
import * as dat from 'dat.gui';

class ThreeContainer {
  canvas;
  scene;
  camera;
  renderer;
  plane;
  pointLight;
  paperFlocking;
  previousTime = performance.now();


  constructor(canvas) {
    this.canvas = canvas;
    this.init();
  }

  init() {
    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.Fog( 0xf2f5ff, 20 , 40 );
    this.renderer = this.createRenderer();
    this.camera = this.createCamera();
    this.createLights();
    this.createEffect();
    this.paperFlocking = new PaperFlocking(this.scene);

    this.animate();

    window.addEventListener('resize', this.onWindowResize.bind(this));
}

createPostcards(postcards){
  this.paperFlocking.createPostcards(postcards);
}

createUI() {
  const gui = new dat.GUI();
  
  const bokehFolder = gui.addFolder('Depth of Field');

  const bokehParams = {
      focus: 0.00,
      aperture: 0.01, 
      maxblur: 10
  };

  // Focus control
  bokehFolder.add(bokehParams, 'focus', 0, 100 , 0.01).onChange((value) => {
     this.bokehPass.uniforms.focus.value = value;
  });

  // Aperture control
  bokehFolder.add(bokehParams, 'aperture', 0.0 , 100.0, 0.01).onChange((value) => {
     this.bokehPass.uniforms.aperture.value = value;
  });

  // Maxblur control
  bokehFolder.add(bokehParams, 'maxblur', 0.0 , 100.0, 0.01).onChange((value) => {
    this.bokehPass.uniforms.maxblur.value = value;
  });

  bokehFolder.open();
}

 
  flip = ()=>{
    this.paperFlocking.flip();
  }

  createEffect() {
    this.composer = new EffectComposer(this.renderer);

    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);

    const vignettePass = new ShaderPass(VignetteShader);
    vignettePass.uniforms['offset'].value = 0.8;
    vignettePass.uniforms['darkness'].value = 0.8;
    this.composer.addPass(vignettePass);

    this.noisePass = new ShaderPass(NoiseShader);
    this.composer.addPass(this.noisePass);
}


  createRenderer() {
    const renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    renderer.setClearColor(0xe4ecff, 1);

    return renderer;
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  createCamera() {
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 40);
    camera.position.set(0, 0, 20);
    this.scene.add(camera);
    return camera;
  }

  createLights() {
    this.pointLight = new THREE.PointLight('#fffff4', 0, 50);
    this.pointLight.position.set(0, 20, 10);
    this.scene.add(this.pointLight);

    const ambientLight = new THREE.AmbientLight('#fff7ef', 2.5);
    this.scene.add(ambientLight);
  }


  setCurrentPostcad(currentPostcard){
    this.paperFlocking.setCurrentPostcad(currentPostcard);
  }

 

  animate() {
    requestAnimationFrame(this.animate.bind(this));

    const currentTime = performance.now();
    const deltaTime = (currentTime - this.previousTime); 
    this.previousTime = currentTime;
    this.paperFlocking.update(deltaTime);
    this.noisePass.uniforms['time'].value += (deltaTime % 100) / 10000;
    this.composer.render();
  }
}

export default ThreeContainer;
