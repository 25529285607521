import { useEffect, useRef, useState } from "react";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { APP_AREAS, APP_STATE, randomFloat } from "../../utils/Utils";
import { getSentPostcards } from "../../utils/WebService";
import ThreeContainer from './webgl/ThreeContainer';
import Header from "./header/Header";
import About from "./about/About";
import Home from "./home/Home";
import GetInvolved from "./get_involved/GetInvolved";
import styled from "styled-components";
import Loader from "./loader/Loader";
import gsap from "gsap";
import Footer from "./footer/Footer";
import Gallery from './gallery/Gallery'

const Container = styled.div`
    width: 110vw;
    height: 110vh;
`;

const StyledCanvas = styled.canvas`
  width: 110vw;
  height: 110vh;
  display: block;
  background: #000;
`;

const HTMLWrapper = styled.div.withConfig({
    shouldForwardProp:  (prop) => prop !== 'isScrollDisabled'
  })`
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: ${({ isScrollDisabled }) => (isScrollDisabled ? 'hidden' : 'scroll')};
  `;


const WebContainer = () => {
    let canvasRef = useRef(null);
    let wrapperRef = useRef(null);

    let [threeContainer, setThreeContainer] = useState(null);
    let [postcards, setPostcards] = useState([]);
    let [currentPostcard, setCurrentPostcards] = useState(null);

    let [postcardsLoaded, setPostcardsLoaded] = useState(false);
    let [scrollPos, setScrollPos] = useState(0); 
    let [progress, setProgress] = useState(0); 
    let [isMenuOpen, setIsMenuOpen] = useState(true);
    let [isScrollDisabled, setIsScrollDisabled] = useState(true); // Added state to control scrolling

    gsap.registerPlugin(ScrollToPlugin);

    useEffect(() => {
        if (progress > 99) return;
        const interval = setTimeout(() => {
            setProgress(progress + 1);
        }, 10);
        return () => {
            clearTimeout(interval)
            // onOpenComplete();
        };
    }, [progress]);

    useEffect(() => {
        const loadPostcards = async () => {
            try {
                const query = await getSentPostcards();
                console.log("Postcards response:", query); // Log to verify structure

                // Ensure postcards are correctly set if they exist
                if (query.postcards && query.postcards.length > 0) {
                    setPostcards(query.postcards);
                    setPostcardsLoaded(true);

                    console.log('query.postcards: ' , query.postcards);

                    if (threeContainer) {
                        threeContainer.createPostcards(query.postcards);
                    }
                } else {
                    console.warn("No postcards found in the response.");
                }
            } catch (error) {
                console.error("Error fetching postcards:", error);
            }
        };

        if (canvasRef.current && !threeContainer) {
            setThreeContainer(new ThreeContainer(canvasRef.current));
        }

        if (threeContainer && !postcardsLoaded) {
            loadPostcards();
        }
    }, [threeContainer, postcardsLoaded]);


    const scrollToPosition = (position, time = 1) => {
        if (wrapperRef.current) {
            gsap.to(wrapperRef.current, {
                scrollTo: position,
                duration: time,
                ease: "power2.inOut",
                onUpdate: () => {
                    const scrollNormal = (wrapperRef.current.scrollTop + 60) / (window.innerHeight * APP_AREAS.length - 1); 
                    setScrollPos(scrollNormal);
                },
                onComplete: () => {}
            });
        }
    };

    // Area selection handling logic
    const onSelectArea = (area, time) => {
        let pos = 0;
        switch(area) {
            case APP_STATE.gallery:
                pos = 0 * window.innerHeight;
                break;
            case APP_STATE.about:
                pos = 1 * window.innerHeight;
                pos -= 60;
                break;
            default:
                pos = 0;
                break;
        }
        scrollToPosition(pos, time);
    };

    const onOpenComplete = () => {
        setIsMenuOpen(false);
        setIsScrollDisabled(false);
    };

    const touchHandler = (evt)=>{

        let x = 0;

        if(evt.type === 'click')
        {
            x = evt.clientX;
        }
        else
        {
            const touch = evt.changedTouches[0];
            x = touch.screenX;
        }

        
        if(x < window.innerWidth * 0.1)
            prevPostcards();
        else if(x > window.innerWidth * 0.9)
            nextPostcard();
        else 
            flipImage();
        
    }

    const flipImage = ()=>{

        threeContainer.flip();

    }

    const nextPostcard = ()=>{

        let postcard = currentPostcard + 1;
        if(postcards.length === postcard)
            postcard = 0;

        setCurrentPostcards(postcard);
        threeContainer.setCurrentPostcad(postcard);

    }

    const prevPostcards = ()=>{
        let postcard = currentPostcard - 1;
        if(postcard < 0)
            postcard = postcards.length - 1;

        setCurrentPostcards(postcard);
        threeContainer.setCurrentPostcad(postcard);
    }

    const getHome = ()=>{
        
        if(isMenuOpen)
            return <Home scrollPos={scrollPos} progress={progress} onOpenComplete={onOpenComplete} />
        else
            return <Gallery postcards={postcards}></Gallery>


    }

    return (
        <Container>
            <Header onSelectArea={onSelectArea} isOpen={isMenuOpen} />
            <Loader progress={progress} />
            <HTMLWrapper ref={wrapperRef} isScrollDisabled={isScrollDisabled}>
                {getHome()}
                <About />
                <GetInvolved />
                <Footer />
            </HTMLWrapper>
            <StyledCanvas ref={canvasRef}></StyledCanvas>
        </Container>
    );
};

export default WebContainer;
