import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


// Styled-component for styling
const ShufflerWrapper = styled.div`
  font-family: 'IBM Plex Mono', sans-serif;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  color: black; /* Set text color to black */

  p {
    padding: 0px;
    margin: 0px;
    font-size: 12px;
  }
`;


const LetterShuffler = ({ 
  word, 
  duration = 2000, 
  interval = 50, 
  speed = 2, 
  loop = false, 
  loopInterval = 1000, 
  shuffleCount = 3, // How many letters to shuffle during the loop
  startDelay = 0 // Delay before starting the shuffle
}) => {
  const [shuffledText, setShuffledText] = useState(word);

  // Extended characters including letters, numbers, symbols, and emojis
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}|:"<>?';

  useEffect(() => {
    let animationFrameId;
    let loopTimeoutId;
    let shufflingTimeoutId;
    let startDelayTimeoutId;

    // Function to shuffle letters progressively
    const shuffleLetters = (initial) => {
      const startTime = Date.now();

      const updateShuffling = () => {
        const elapsed = Date.now() - startTime;
        const progress = Math.min((elapsed / duration) * speed, 1);

        let newText = word
          .split('')
          .map((char, idx) => {
            if (initial || Math.random() < 0.3) {
              return idx < Math.floor(progress * word.length)
                ? char
                : characters[Math.floor(Math.random() * characters.length)];
            }
            return char;
          })
          .join('');

        setShuffledText(newText);

        if (progress < 1) {
          animationFrameId = requestAnimationFrame(updateShuffling);
        } else {
          if (loop) {
            loopTimeoutId = setTimeout(() => shuffleFewLetters(), loopInterval);
          }
        }
      };

      updateShuffling();
    };

    // Function to shuffle a few random letters progressively
    const shuffleFewLetters = () => {
      const startTime = Date.now();
      const indicesToShuffle = [];

      // Randomly select letters to shuffle
      while (indicesToShuffle.length < shuffleCount) {
        const randomIndex = Math.floor(Math.random() * word.length);
        if (!indicesToShuffle.includes(randomIndex)) {
          indicesToShuffle.push(randomIndex);
        }
      }

      const updateShuffling = () => {
        const elapsed = Date.now() - startTime;
        const progress = Math.min((elapsed / duration) * speed, 1);

        let newText = word.split('').map((char, idx) => {
          if (indicesToShuffle.includes(idx)) {
            return idx < Math.floor(progress * word.length)
              ? char
              : characters[Math.floor(Math.random() * characters.length)];
          }
          return char;
        });

        setShuffledText(newText.join(''));

        if (progress < 1) {
          animationFrameId = requestAnimationFrame(updateShuffling);
        } else {
          shufflingTimeoutId = setTimeout(() => {
            setShuffledText(word);

            // Continue shuffling after returning to the original word if looping
            if (loop) {
              loopTimeoutId = setTimeout(() => shuffleFewLetters(), loopInterval);
            }
          }, interval);
        }
      };

      updateShuffling();
    };

    // Start the shuffle with an optional delay
    startDelayTimeoutId = setTimeout(() => {
      shuffleLetters(true);
    }, startDelay);

    return () => {
      // Clear all timeouts and animation frames when unmounting
      cancelAnimationFrame(animationFrameId);
      clearTimeout(loopTimeoutId);
      clearTimeout(shufflingTimeoutId);
      clearTimeout(startDelayTimeoutId);
    };
  }, [word, duration, speed, loop, loopInterval, shuffleCount, interval, startDelay]);

  return (
    <ShufflerWrapper>
      <p>{shuffledText}</p>
    </ShufflerWrapper>
  );
};

export default LetterShuffler;
