import styled from "styled-components";
import BaseWrapper from "../common/BaseArea";
import { useEffect, useState } from "react";
import { useRive, useStateMachineInput , EventType } from '@rive-app/react-canvas';
import riveFile from '../../../assets/anim/intro_with_love_from_ai.riv';
import riveFileMobile from '../../../assets/anim/intro_with_love_from_ai_mobile.riv';



import TypingMessage from "./TypingMessage";
import { aiMessages, humanMessages } from "./Messages";
import { randomFloat } from "../../../utils/Utils";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";


const Wrapper = styled(BaseWrapper)`
    justify-items: center;
    align-content: center;
    position: relative; 
`;

const Home = ({ progress, onOpenComplete }) => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {

        
        const interval = setInterval(() => {

            const isHuman = randomFloat(0,100) > 50 ? true : false ;
            const arr = isHuman ? humanMessages : aiMessages;

            const randomMessage = arr[Math.floor(Math.random() * aiMessages.length)];

            const newMessage = (
                <TypingMessage
                    key={Date.now() + Math.random()}
                    text={randomMessage}
                    writeTime={randomFloat(500,1500)} 
                    stayTime={randomFloat(3000,7000)}
                    containerWidth={window.innerWidth * 0.7}
                    containerHeight={window.innerHeight * 0.7}
                    isHuman={isHuman}
                    onFinish={() => {
                        setMessages((prev) => prev.slice(1)); 
                    }}
                />
            );

            setMessages((prev) => [...prev, newMessage]);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const { rive, RiveComponent } = useRive({
        src: window.innerWidth < CSSGlobalVars.phoneCap ? riveFileMobile : riveFile,
        stateMachines: 'Animation',
        autoplay: true,
    });

    let canPlay = useStateMachineInput(rive, "Animation", 'canPlay');
    let idle = useStateMachineInput(rive, "Animation", 'Idle');

    const onRiveEventReceived = (riveEvent) => {
       
        const {data} = riveEvent;

        if(data[0] === 'Outro'){

            setTimeout(() => {
                onOpenComplete();
            }, 1000);

        }

    };

    useEffect(()=>{
        if(rive)
            rive.on(EventType.StateChange, onRiveEventReceived);
    },[rive])

    useEffect(() => {
        if (canPlay && progress === 100 && !canPlay.value) {
            canPlay.value = true;
            setTimeout(() => {
                idle.value = true;
            }, 2000);
           
        }
    }, [progress, canPlay, idle, onOpenComplete]);

   
    return (
        <Wrapper>
            <RiveComponent />
            {messages}
        </Wrapper>
    );
};

export default Home;
