import styled from "styled-components";
import BaseWrapper from "../common/BaseArea";
import { useEffect, useRef, useState } from "react";
import { randomFloat } from "../../../utils/Utils";
import { aiMessages, humanMessages } from '../home/Messages'
import TypingMessage from "../home/TypingMessage";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";

import btRight from '../../../assets/images/gallery/R.png'
import btLeft from '../../../assets/images/gallery/L.png'
import gsap, { Quad , Quart , Quint, random } from "gsap";
import { randFloat } from "three/src/math/MathUtils.js";




const Wrapper = styled(BaseWrapper)`

    display: grid;
    justify-items: center;
    align-items: center;

`


const NavigationContainer = styled.div`

    overflow: hidden;

    position: absolute;
    background: white;
    width: 70px;
    height: 30px;
    left: calc(50% + 330px);
    
    bottom: calc(50% - (330px));
    
    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        left: calc(50% + 105px);
        bottom: calc(50% - (350px * 2 / 3 * 0.8) + 30px);
        
    }
    

    display: grid;
    grid-template-columns: 35px 35px;
    justify-items: center;
    align-items: center;
`

const Arrow = styled.img`

    cursor: pointer;
    width: 15px;
    height: 15px;
`



const PostcarPhotoContainer = styled.div`
    position: absolute;
    display: grid;
    justify-items: left;
    align-items: center;
    overflow: hidden;

`

const PostcarPhoto = styled.img`

    aspect-ratio: 3/2;
    width: 800px;
    object-fit: cover;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        width: 350px;
    }
`

const MessageContainer = styled.div`

    position: absolute;
    z-index: 3;

    width: 40%;
    background: white;

    overflow: hidden;
`

const Message = styled.p`

    font-family: IBM Plex Mono;
    width: 300px;
    font-size: 12px;
    text-align: left;

    margin: 30px;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        font-size: 10px;
        margin: 10px;
    }

`

const Gallery = ({postcards})=>{

    const [currentPostcard , setCurrentpostcar] = useState(0);
    const navRef = useRef(null); 
    const photoRef = useRef(null); 
    const messageRef = useRef(null); 
    const wrapperRef = useRef(null); 

    useEffect(()=>{

        if(!wrapperRef.current)return;

        gsap.to(wrapperRef.current , {duration:1 , backgroundColor:'rgba(255,255,255,0.7)'})

    },[wrapperRef])

    useEffect(()=>{

        if(postcards.length ===0)return;

        changePostcard(Math.floor(randomFloat(0,postcards.length - 1)), 0,1)

    }, [postcards])

    const [messages, setMessages] = useState([]);

    useEffect(() => {

        
        const interval = setInterval(() => {

            const isHuman = randomFloat(0,100) > 50 ? true : false ;
            const arr = isHuman ? humanMessages : aiMessages;

            const randomMessage = arr[Math.floor(Math.random() * aiMessages.length)];

            const newMessage = (
                <TypingMessage
                    key={Date.now() + Math.random()}
                    text={randomMessage}
                    writeTime={randomFloat(500,1500)} 
                    stayTime={randomFloat(3000,7000)}
                    containerWidth={window.innerWidth * 0.7}
                    containerHeight={window.innerHeight * 0.7}
                    isHuman={isHuman}
                    onFinish={() => {
                        setMessages((prev) => prev.slice(1)); 
                    }}
                />
            );

            setMessages((prev) => [...prev, newMessage]);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    useEffect(()=>{

        
        if(!navRef.current)return;

        gsap.to(navRef.current , {width:0,duration:0});
        gsap.to(navRef.current , {width:70,duration:0.2 , ease:Quad.easeInOut});

    },[navRef])

    const sideHandler = (side)=>{

        let next = currentPostcard + side;

        if(next<0)
            next = postcards.length - 1;
        else if(next === postcards.length)
            next = 0;


        changePostcard(next , randomFloat(0.2 , 0.3)  , randomFloat(0.5 , 1));

    }



    const changePostcard = (index , durationHide , durationShow)=>{


        const cardWidth = window.innerWidth > 700 ? 800  : 350  ;
        const cardHeight = cardWidth * (2/3);
        

        gsap.to(photoRef.current , {width:0 , duration:durationHide ,ease:Quint.easeOut , onComplete:()=>{

            setCurrentpostcar(index);

            const left =  (window.innerWidth - cardWidth) / 2;
            const top =  (window.innerHeight - cardHeight) / 2;

            const delay = randomFloat(0,0.5);
            gsap.to(photoRef.current , {top:top ,  left:left, duration:0});
            gsap.to(photoRef.current , {width:'100%' , duration:durationShow ,ease:Quint.easeInOut , delay:delay , onComplete:()=>{
            }})
        }})


        const maxWidth = window.innerWidth > 700 ? 400 : 350;
        const minWidth = window.innerWidth > 700 ? 400 : 300;

        gsap.to(messageRef.current , {width:0 , duration:durationHide ,ease:Quint.easeOut , onComplete:()=>{
            
            const textBoxWidth = randomFloat(minWidth , maxWidth);

            const leftOption = ((window.innerWidth - cardWidth) / 2) - randomFloat(5,cardWidth * 0.05);
            const rightOption = ((window.innerWidth - cardWidth) / 2) + cardWidth - randFloat(textBoxWidth * 0.9 , textBoxWidth);
            
            const left =  randomFloat(0,100) > 50 ? leftOption :  rightOption;


            const top =  randFloat((window.innerHeight - cardHeight) / 2 , ((window.innerHeight - cardHeight) / 2) + cardHeight * 0.5 );
            const delay = randomFloat(0,0.5);


            const colors = ['#BB75F9' , '#EA5CFF' , '#FFFFFF']
            const color = colors[Math.round(randomFloat(0,colors.length - 1))]

            gsap.to(messageRef.current , {backgroundColor:color});

            gsap.to(messageRef.current , {top:top, left:left, duration:0});
            gsap.to(messageRef.current , {width:textBoxWidth , duration:durationShow ,ease:Quint.easeInOut  , delay:delay , onComplete:()=>{
            }})
        }})

        

    }


    const formatMessageWithSignature = (message)=> {
        // Regular expression to match "With Love, A.I." or "With Love A.I."
    const signaturePattern = /With\s+Love,?\s+A\.I\./;
    
    // Replace matched signature with a line break before it
    return message.replace(signaturePattern, `<br /><br />With Love, A.I.`);
    }

    return(
        <Wrapper ref={wrapperRef}>
           {messages}
           <NavigationContainer ref={navRef}>
                <Arrow onClick={()=>{sideHandler(-1)}} src={btLeft}></Arrow>
                <Arrow onClick={()=>{sideHandler(1)}} src={btRight}></Arrow>
           </NavigationContainer>
           <PostcarPhotoContainer ref={photoRef}>
            <PostcarPhoto src={postcards[currentPostcard].photoUrl}/>
           </PostcarPhotoContainer>
           <MessageContainer ref={messageRef}>
            <Message dangerouslySetInnerHTML={{ __html: formatMessageWithSignature(postcards[currentPostcard].message)}}></Message>
           </MessageContainer>
        </Wrapper>
    )
}

export default Gallery;
