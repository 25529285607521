import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import styled from "styled-components";


const CameraStyle = styled.div`

  height: 100vh;
 
  
  .postcard-video {
    height: 100vh;
    object-fit:cover;
    
  }

  .postcard-canvas{
    height: 100vh;
    position: absolute;
    z-index: 3;
    display: none;
  }

`

const Camera = forwardRef((props, ref) => {

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    startVideo();
  }, []);

  useImperativeHandle(ref, () => ({
    takePicture: () => {
      return new Promise((resolve) => {
        const videoElement = videoRef.current;
        const canvasElement = canvasRef.current;

        if (!videoElement || !canvasElement) {
          console.error("Video or Canvas element not found!");
          resolve(null);
          return;
        }

        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;

        const ctx = canvasElement.getContext('2d');
        ctx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

        const imageData = canvasElement.toDataURL('image/png', 1.0);

        if (imageData && imageData.length > 10) {
          resolve(imageData);
        } else {
          console.error('Failed to capture image data.');
          resolve(null);
        }
      });
    }
  }));

  const startVideo = () => {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then(stream => {
        const videoElement = videoRef.current;

        if (!videoElement) return;

        videoElement.srcObject = stream;

        videoElement.onloadedmetadata = () => {
          videoElement.play();
        };

        videoElement.addEventListener('canplay', () => {
          console.log('Video is playing and ready for capturing.');
          const canvasElement = canvasRef.current;

          if (canvasElement && videoElement) {
            canvasElement.width = videoElement.videoWidth;
            canvasElement.height = videoElement.videoHeight;
          }
        });
      })
      .catch(error => {
        console.error("Error accessing the webcam", error);
      });
  };

  const switchCamera = (isFrontCamera) => {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: isFrontCamera ? 'user' : 'environment' } })
      .then(stream => {
        const videoElement = videoRef.current;
        videoElement.srcObject = stream;
        videoElement.play();
      })
      .catch(error => {
        console.error("Error accessing the webcam", error);
      });
  };

  return (
    <CameraStyle>
      <canvas id='postcard-canvas' className='postcard-canvas' ref={canvasRef} />
      <video id='postcard-video' className='postcard-video' ref={videoRef} autoPlay playsInline />
    </CameraStyle>
  );
});

export default Camera;
