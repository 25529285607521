import styled from "styled-components";



 const BaseWrapper = styled.div`
    width: 100%; 
    height: 100vh;
    //padding-left: 10%;
`


export default BaseWrapper;