import * as THREE from 'three';

export const systemMessages = [
    "SYSTEM: BOOTLOAD.. >>",
    "SYSTEM: INITIALIZING... >>",
    "SYSTEM: VALIDATION [PASS]",
    "CAMERA 01: [ONLINE]",
    "CAMERA 02: [ONLINE]",
    "CAMERA 03: [OFFLINE]",
    "SENSOR ARRAY: [ACTIVE]",
    "NETWORK: CONNECTING...",
    "NETWORK: [CONNECTED]",
    "AI MODULE: LOADING... >>",
    "AI MODULE: [ONLINE]",
    "MEMORY: ALLOCATING RESOURCES... >>",
    "MEMORY: [OPTIMIZED]",
    "GPU STATUS: [OVERCLOCKED]",
    "CPU STATUS: [NORMAL]",
    "DATABASE: SYNCHRONIZING... >>",
    "DATABASE: [UPDATED]",
    "DRONE 01: [LAUNCHED]",
    "DRONE 02: [STANDBY]",
    "LASER GRID: [ACTIVATED]",
    "LASER GRID: [DEACTIVATED]",
    "SYSTEM CLOCK: SYNCING... >>",
    "POWER CORE: [FULL]",
    "SECURITY OVERRIDE: [AUTHORIZED]",
    "ENVIRONMENTAL SENSORS: [CALIBRATED]",
    "OPERATING SYSTEM:[COMPLETE]",
    "TERMINAL: WAITING FOR COMMAND...",
    "SERVER: RESTARTING... >>",
    "SERVER: [ONLINE]",
    "REMOTE ACCESS: [ENABLED]",
    "USER AUTH: [VERIFIED]",
    "BIOMETRICS: [CONFIRMED]",
    "BACKUP GENERATOR: [STANDBY]",
    "ROUTER 01: [REBOOTED]",
    "SYSTEM SCAN: IN PROGRESS... >>",
    "SYSTEM SCAN: [CLEAR]",
    "FIREWALL: [ENABLED]",
    "FIREWALL: [BREACH DETECTED]",
    "DATA CACHE: CLEARING... >>",
    "ERROR LOG: NO ENTRIES",
    "ERROR LOG: [1 ENTRY]",
    "SATELLITE LINK: [CONNECTED]",
    "SIGNAL: [WEAK]",
    "SIGNAL: [STRONG]",
    "CPU TEMP: [NORMAL]",
    "HARD DRIVE: [HEALTHY]",
    "SYSTEM SHUTDOWN: [INITIATED]",
    "RESTART: [COMPLETE]",
  ];
  

 export function getRandomSystemMessage() {
    const randomIndex = Math.floor(Math.random() * systemMessages.length);
    return systemMessages[randomIndex];
  }

/**
 * Returns a random float between min and max (inclusive).
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} - A random float between min and max.
 */
export function randomFloat(min, max) {
    return Math.random() * (max - min) + min;
}

/**
 * Returns a random THREE.Vector3 with x, y, and z components 
 * ranging from their respective min and max values.
 * @param {object} xRange - An object with min and max for the x component.
 * @param {object} yRange - An object with min and max for the y component.
 * @param {object} zRange - An object with min and max for the z component.
 * @returns {THREE.Vector3} - A random THREE.Vector3.
 */
export function randomVector3(xRange, yRange, zRange) {
    const x = randomFloat(xRange.min, xRange.max);
    const y = randomFloat(yRange.min, yRange.max);
    const z = randomFloat(zRange.min, zRange.max);
    return new THREE.Vector3(x, y, z);
}

export const createCircularGradientTexture = (size)=> {
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');

    // Create a radial gradient
    const gradient = context.createRadialGradient(size / 2, size / 2, 0, size / 2, size / 2, size / 2);
    gradient.addColorStop(0, 'rgba(255, 250, 244, 1)'); // Center color
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0.0)'); // Fade to transparent
    

    // Apply the gradient to the canvas
    context.fillStyle = gradient;
    context.fillRect(0, 0, size, size);

    // Create a texture from the canvas
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true; // Ensure the texture is updated
    return texture;
  }


  export const APP_STATE={
    home:'home',
    gallery:'gallery',
    about:'about',
  }

  export const APP_AREAS = [
    APP_STATE.home ,
    APP_STATE.gallery , 
    APP_STATE.about , 
  ]


  export const map =  (value, inMin, inMax, outMin, outMax) => {
    // Map the input value to the target range
    let mappedValue = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

    // Clamp the mapped value within the output range
    if (mappedValue < outMin) {
        mappedValue = outMin;
    } else if (mappedValue > outMax) {
        mappedValue = outMax;
    }

    return mappedValue;
}