import styled from "styled-components";


const DataContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['align', 'height', 'textAlign' , 'marginTop' , 'marginLeft' , 'fontColor' , 'justify'].includes(prop),
  })`
    width: 100%;
    align-self: ${(props) => props.align || 'flex-start'};
    height: ${(props) => props.height || '0px'};
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.justify || 'center'};;
    margin-top: ${(props) => props.marginTop || '0px'};
    margin-left: ${(props) => props.marginLeft || '0px'};
    
    
    
  
    p {
      padding: 0px;
      margin: 0px;
      font-size: 12px;
      text-align: ${(props) => props.textAlign || 'left'};
      color:${(props) => props.fontColor || '000'};
    }
  `;

  export default DataContainer;