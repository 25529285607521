import styled from "styled-components";
import gsap , {Quad} from 'gsap';
import { useEffect, useRef, useState } from "react";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";
import LetterShuffler from "../common/LetterShuffler";
import DataContainer from "../common/DataContainer";
import { getRandomSystemMessage , randomFloat } from "../../../utils/Utils";
import logo from '../../../assets/images/logo.png'


const Wrapper = styled.div`

    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 4;
    overflow: hidden;
`

const Container = styled.div`

    width: 100vw;
    height: 100vh;
    background: ${CSSGlobalVars.primaryColor};
    color: white;
    display: grid;
    grid-template-rows: 30% 40% 30%;

    align-items: center;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
  
        grid-template-rows: 40% auto 40%;

    }  

`

const TopDiv = styled.div`

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-items: center;

`

const Progress = styled.p`
    font-family: Tiposka;
    font-size: 12em;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
  
        font-size: 5em;

    }  

`

const Logo = styled.img`

    width: 200px;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
  
        width: 100px;

    }  

`

const Loader = ({progress = 0})=>{

    const wrapper = useRef(null);
    let [hidden , setHidden] = useState(false);

    useEffect(()=>{

        const close = ()=>{
            gsap.to(wrapper.current , {width:0 , duration:0.5 , ease:Quad.easeInOut});
            setHidden(true);
        }

        if(progress === 100 && !hidden)
          close();

    },[progress , hidden])
        


    return(
        <Wrapper ref={wrapper}>
            <Container>
                <TopDiv>
                    <DataContainer align={'center'} height={'200px'} textAlign={'left'} marginTop={'0%'} fontColor={'#ffffff'} marginLeft={'10%'}  justify={'space-around'}>
                        <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                        <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                        <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                        <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                        <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                        <LetterShuffler  word={getRandomSystemMessage()}  loop={true} loopInterval={randomFloat(500,2000)} startDelay={randomFloat(0,1000)} />
                    </DataContainer>
                    <Logo src={logo}></Logo>
                </TopDiv>
                <Progress>{`${progress}%`}</Progress>
            </Container>
        </Wrapper>
    )
}

export default Loader;
