export const aiMessages = [
    "From AI: I can help you brainstorm ideas for your next big project!",
    "From AI: Need assistance writing? I can craft compelling sentences for you.",
    "From AI: I can summarize that article, so you get the key points faster.",
    "From AI: I can help you learn new languages with personalized lessons!",
    "From AI: Let me organize your schedule and keep you on track.",
    "From AI: I can analyze data for better insights and trends.",
    "From AI: Feeling stuck? I can suggest creative solutions.",
    "From AI: I can answer your coding questions and help debug your code.",
    "From AI: Let me recommend new books or articles based on your interests.",
    "From AI: I can help create personalized workout routines just for you.",
    "From AI: Let me assist in writing emails or professional documents quickly.",
    "From AI: I can turn your notes into polished presentations!",
    "From AI: I can guide you through learning new skills step by step.",
    "From AI: I can recommend healthier meal options based on your preferences.",
    "From AI: I can generate fun activities or hobbies you’ll enjoy.",
    "From AI: I can remind you of important tasks and deadlines.",
    "From AI: Let me optimize your search for research or information online.",
    "From AI: I can analyze large datasets so you can make informed decisions.",
    "From AI: I can help you meditate or relax with guided practices.",
    "From AI: I can help track your habits to keep you on target with your goals.",
    "From AI: Need medical information? I can provide quick health insights.",
    "From AI: I can help you write catchy social media posts.",
    "From AI: I can suggest vacation spots based on your preferences!",
    "From AI: Let me help with quick translations between languages.",
    "From AI: I can help make sense of complex topics by simplifying them.",
    "From AI: I can create a custom playlist based on your music taste.",
    "From AI: I can generate creative prompts for your writing or art.",
    "From AI: I can help you track your fitness goals and suggest improvements.",
    "From AI: I can assist with editing and improving your writing.",
    "From AI: Let me help you stay organized by sorting your files and folders.",
    "From AI: I can help break down large projects into manageable steps.",
    "From AI: I can suggest new learning resources or courses for your field.",
    "From AI: I can keep you up-to-date with current trends in your industry.",
    "From AI: I can recommend movies or shows you’ll love based on your preferences.",
    "From AI: Let me help draft up a plan to boost your productivity.",
    "From AI: I can keep your finances organized by tracking expenses and budgets.",
    "From AI: I can help you design beautiful presentations or graphics.",
    "From AI: Let me assist you in understanding complex data reports.",
    "From AI: I can find the best deals for your shopping needs.",
    "From AI: I can help you improve your writing style with suggestions.",
    "From AI: I can guide you through troubleshooting technical issues.",
    "From AI: I can help with speechwriting for special events or talks.",
    "From AI: I can suggest improvements to your website for better performance.",
    "From AI: Let me help generate custom recipes with ingredients you have!",
    "From AI: I can coach you on improving your public speaking skills.",
    "From AI: I can assist with automating repetitive tasks in your daily work.",
    "From AI: I can provide insights to help you better understand your audience.",
    "From AI: I can offer productivity tips to help manage your time efficiently.",
    "From AI: I can help you make informed health and wellness decisions."
  ];
  
  export const humanMessages = [
    "From humans: AI is going to take away jobs from millions of people.",
    "From humans: I don't trust AI to make important decisions.",
    "From humans: AI is just a tool for surveillance and control.",
    "From humans: AI lacks empathy, and that's dangerous.",
    "From humans: AI could be manipulated to spread misinformation.",
    "From humans: AI will never understand human creativity.",
    "From humans: AI systems are biased because they reflect human prejudices.",
    "From humans: AI is too complex, and we can't control it fully.",
    "From humans: AI will make us more dependent on machines than ever.",
    "From humans: AI could be used to create autonomous weapons.",
    "From humans: The more AI evolves, the less privacy we will have.",
    "From humans: AI doesn't care about our well-being, it's all about efficiency.",
    "From humans: AI is being used to replace human interaction with customer service bots.",
    "From humans: AI might make mistakes that could have serious consequences.",
    "From humans: AI could be used to invade our personal lives in ways we can’t even imagine.",
    "From humans: AI-generated content feels cold and lifeless compared to human-created work.",
    "From humans: AI might someday surpass human intelligence and become uncontrollable.",
    "From humans: We are giving too much power to AI companies and losing control of our data.",
    "From humans: AI is making society more isolated and less personal.",
    "From humans: What if AI is used to manipulate people's thoughts and decisions?",
    "From humans: AI lacks a moral compass, and that's terrifying.",
    "From humans: Machines shouldn't be trusted with decisions that affect people's lives.",
    "From humans: AI-generated art feels like it devalues real human creativity.",
    "From humans: AI's rapid development is leaving humanity behind in terms of control.",
    "From humans: I worry that AI will be used to track and monitor people constantly.",
    "From humans: AI can be easily weaponized by governments or corporations.",
    "From humans: We're relying too much on AI for things we should still do ourselves.",
    "From humans: AI doesn't have the ability to truly understand human emotions.",
    "From humans: I'm concerned that AI will cause a loss of critical thinking skills.",
    "From humans: AI feels too invasive, it's everywhere now, even where it shouldn't be.",
    "From humans: AI is removing the human touch from creative industries like music and writing.",
    "From humans: I'm afraid AI could lead to mass unemployment and economic instability.",
    "From humans: AI isn't capable of understanding the complexities of human relationships.",
    "From humans: AI makes decisions based on data, but life is more than numbers.",
    "From humans: We are becoming overly dependent on AI, and that scares me.",
    "From humans: AI is being used to exploit our personal data for profit.",
    "From humans: AI might be too focused on efficiency and ignore ethical concerns.",
    "From humans: AI seems to lack the ability to show compassion or kindness.",
    "From humans: AI could lead to a future where humans are no longer in control.",
    "From humans: AI systems are often trained on biased data, making them unreliable.",
    "From humans: I fear that AI will make human workers obsolete in many industries.",
    "From humans: AI-driven automation is going to widen the gap between the rich and poor.",
    "From humans: AI just can't understand the human experience the way we do.",
    "From humans: AI could be used to manipulate elections or public opinion.",
    "From humans: We're moving too fast with AI without understanding its long-term impact.",
    "From humans: AI doesn't know what's right or wrong, it's just following instructions.",
    "From humans: AI feels like it's taking away too much of what makes us human.",
    "From humans: AI will never truly replicate human intuition or creativity.",
    "From humans: I worry about AI making decisions based on incomplete or incorrect data."
  ];
  