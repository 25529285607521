import * as THREE from 'three';
import {randomVector3 , randomFloat} from '../../../utils/Utils';
import CSSGlobalVars from '../../../utils/CSSGlobalVars';
import gsap , {Back} from 'gsap';

class Paper {

    STATES = {

        loading:'loading',
        ready:'reday'
    }
    
    paperState = this.STATES.loading;
    front = true;

    currentRotation = 0;


    constructor(startPosition, speed, startRadius, angle, scene, lookAtPoint, frontTextureUrl, backTextureUrl , index , back) {

        this.back = back;
        this.startCurvature = randomFloat(-1,1);
        this.selected = false;
        this.index = index;
        this.startAngle = angle;
        this.lookAtPoint = lookAtPoint;
        this.startPosition = startPosition;
        this.speed = speed;
        this.startRadius = startRadius;
        this.radius = startRadius;
        this.angle = angle;
        this.radiusTo = this.radius;
        this.rotation = new THREE.Vector3(0, 0, 0);
        this.randAdd = randomVector3({ min: -0.02, max: 0.02 }, { min: -0.02, max: 0.02 }, { min: -0.02, max: 0.02 });
        this.randAdd.x *= speed * 0.3;
        this.randAdd.y *= speed * 0.5;
        this.randAdd.z *= speed * 0.5;
        this.posTo = new THREE.Vector3();
        this.rotTo = new THREE.Vector3();
        this.speed = speed;
        this.accell = randomFloat(50, 100) / 300000;
        this.frontTexture = frontTextureUrl;
        this.backTexture = backTextureUrl;
        this.scene = scene;
        
    
        this.init(frontTextureUrl , backTextureUrl , scene);
        
        
    }

    init =  async(frontTextureUrl , backTextureUrl , scene)=>{ 

            this.material = await this.createMaterial(frontTextureUrl, backTextureUrl);

            this.createGeometry();
            this.setupMesh(scene);
            this.setStartPosition();
            this.randomizePositionTarget();
            this.paperState = this.STATES.ready;  
    }
    
    createGeometry() {
        const scale = 0.7;
        const aspectRatio = 1 / 1.41;
        const width = 3 * scale;
        const height = width * aspectRatio;
        this.geometry = new THREE.PlaneGeometry(width, height, 10, 2);
    }
    

    createMaterial(frontTextureUrl) {
    

        return new Promise((resolve, reject) => {
            const textureLoader = new THREE.TextureLoader();
    
            textureLoader.load(
                frontTextureUrl,
                (frontTexture) => {
                            const baseMaterial = new THREE.MeshStandardMaterial({
                                metalness: 0.0,
                                roughness: 100.0,
                                side: THREE.DoubleSide,
                            });
    
                            const material = baseMaterial.clone();
    
                            material.onBeforeCompile = (shader) => {
                                shader.uniforms.frontTexture = { value: frontTexture };
                                shader.uniforms.backTexture = { value: this.back };
                                shader.uniforms.curvature = { value: this.startCurvature }; 
    
                                shader.uniforms.time = { value: 0.0 };
    
                                shader.vertexShader = `
                                    uniform float curvature;
                                    uniform float time;
                                    varying vec2 vUv;
                                ` + shader.vertexShader;
    
                                shader.vertexShader = shader.vertexShader.replace(
                                    '#include <begin_vertex>',
                                    `
                                    #include <begin_vertex>
    
                                    vUv = uv; // Pass the UV coordinates to the fragment shader
    
                                    float distanceFromCenter = uv.x - 0.5;
                                    // Update curvature over time using a sine wave for example
                                    float dynamicCurvature = curvature + sin(time) * 0.5;
    
                                    // Apply dynamic curvature to the z-coordinate of the vertices
                                    transformed.z += distanceFromCenter * distanceFromCenter * dynamicCurvature;
                                    `
                                );
    
                                // Modify the fragment shader to use the passed UV coordinates
                                shader.fragmentShader = `
                                    uniform sampler2D frontTexture;
                                    uniform sampler2D backTexture;
                                    varying vec2 vUv; // Declare vUv in the fragment shader
                                ` + shader.fragmentShader;
    
                                shader.fragmentShader = shader.fragmentShader.replace(
                                    '#include <map_fragment>',
                                    `
                                    vec4 frontColor = texture2D(frontTexture, vUv);
                                    vec4 backColor = texture2D(backTexture, vUv);
    
                                    // Switch between front and back textures
                                    vec4 color = gl_FrontFacing ? frontColor : backColor;
                                    diffuseColor *= color;
                                    `
                                );
    
                                // Store the shader for later use
                                material.userData.shader = shader;
                            };
    
                            // Resolve the promise with the created material
                            resolve(material);
                        },
                        
                undefined, // onProgress
                (error) => reject(`Error loading front texture: ${error}`)
            );
        });
    }
    
    
    
    // Function to setup mesh
    setupMesh(scene) {
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.rotation.x = Math.PI / 2; // Rotate to face upward
        scene.add(this.mesh);
        this.mesh.name = "Paper";
    }
    
    // Function to set the initial position and randomize rotation
    setStartPosition() {
        this.mesh.position.set(this.startPosition.x, this.startPosition.y, this.startPosition.z);
    
        // Randomize rotation
        this.mesh.rotation.x = randomFloat(0, 180);
        this.mesh.rotation.y = randomFloat(0, 180);
        this.mesh.rotation.z = randomFloat(0, 180);
    }
    
    // Function to randomize position target for movement
    randomizePositionTarget() {
        this.posTo.x = randomFloat(-100, 0);
        this.posTo.y = randomFloat(5, 20);
        this.posTo.z = randomFloat(10, 17);
    }


    setRadius(newRadius) {
        this.radiusTo = newRadius;
    }

    update() {

        if(this.paperState !== this.STATES.ready)return;

        if(this.selected)
        {
            // this.updateSelected();
            // this.updateRotation(0.01);
        }
        else{
            this.spin();
            this.randomRotate();   
            this.updatePosition(0.02);
        }

        
        
    }

    updateSelected = ()=>{
        
        this.posTo.x = 0;
        this.posTo.y = 0;
        this.posTo.z = window.innerWidth > CSSGlobalVars.phoneCap ? 18 : 16;

        this.rotTo.x = 0;
        this.rotTo.y =  this.currentRotation * Math.PI / 180;
        this.rotTo.z = 0;

        //this.material.userData.shader.uniforms.curvature.value = 0;
        
    }

    select = (selected)=>{

        if(!this.material)return;
        this.selected = selected;
        if(!selected)
        {
            gsap.to(this.material.userData.shader.uniforms.curvature , {duration:1 , value:this.startCurvature });
            return
        }

        const x = 0;
        const y = 0;
        const z = window.innerWidth > CSSGlobalVars.phoneCap ? 18.5 : 17;

        gsap.to(this.material.userData.shader.uniforms.curvature , {duration:1 , value:-0.1 });
        gsap.to(this.mesh.rotation , {duration:0 , x:randomFloat(0,360)* Math.PI / 180 , y:randomFloat(0,360)* Math.PI / 180 , z:randomFloat(0,360) * Math.PI / 180 });

        const rx = 0;
        const ry =  this.currentRotation * Math.PI / 180;
        const rz = 0;

        gsap.to(this.mesh.position , {duration:1 , x:x , y:y , z:z });
        gsap.to(this.mesh.rotation , {duration:1 , x:rx , y:ry , z:rz });
    }

    flip = ()=>{
        this.front = !this.front;
        this.currentRotation += 180;

        const rx = 0;
        const ry =  this.currentRotation * Math.PI / 180;
        const rz = 0;

        gsap.to(this.mesh.rotation , {duration:1.5 , x:rx , y:ry , z:rz , ease:Back.easeOut });
    }   


    move = ()=>{

        this.speed += this.accell;

        this.posTo.x += this.speed;

        this.mesh.rotation.x += this.randAdd.x * this.speed
        this.mesh.rotation.y += this.randAdd.y * this.speed
        this.mesh.rotation.z += this.randAdd.z * this.speed


        this.mesh.position.x = this.posTo.x;
        this.mesh.position.y = this.posTo.y;
        this.mesh.position.z = this.posTo.z;

        
        if(this.posTo.x > 25){
            this.posTo.x = -25;
            this.speed = 0;
            this.posTo.y = randomFloat(0,30);
            this.posTo.z = randomFloat(5,17);
        }   
    }

    updatePosition(dump = 1){
    
        this.mesh.position.x += (this.posTo.x - this.mesh.position.x) * dump;
        this.mesh.position.y += (this.posTo.y - this.mesh.position.y) * dump;
        this.mesh.position.z += (this.posTo.z - this.mesh.position.z) * dump;
    }

    updateRotation(dump = 1){
    
        this.mesh.rotation.x += (this.rotTo.x - this.mesh.rotation.x) * 1;
        this.mesh.rotation.y += (this.rotTo.y - this.mesh.rotation.y) * dump;
        this.mesh.rotation.z += (this.rotTo.z - this.mesh.rotation.z) * 1;
    }

    spin() {
        this.radius += (this.radiusTo - this.radius) * 0.2;

        const angleRad = THREE.MathUtils.degToRad(this.angle);
        this.posTo.x = Math.cos(angleRad) * this.radius;
        this.posTo.z = Math.sin(angleRad) * this.radius;
        this.posTo.y = this.startPosition.y;

        this.angle += this.speed;

        if (this.angle >= 360) {
            this.angle = 0;
        }
    }

    galleryAnimation(){

        this.posTo.x += 0.1;
        this.randomRotate()

        if(this.posTo.x > 40)
        {
            const val = randomFloat(-80,-40);
            this.posTo.x = val;
            this.mesh.position.x = val;
            this.posTo.y = randomFloat(10,20);
        }
    
    }


    getInvolvedAnimation(){

        
        this.randomRotate()

        if(this.posTo.z > 40)
        {
            const val = randomFloat(-80,-40);
            this.posTo.z = val;
            this.mesh.position.x = val;
            this.posTo.y = randomFloat(10,20);
        }

       
    }

    randomRotate(){
        this.mesh.rotation.x += this.randAdd.x
        this.mesh.rotation.y += this.randAdd.y
        this.mesh.rotation.z += this.randAdd.z;
    }

    lookToCenter() {
        const targetPosition = new THREE.Vector3();  
        const meshPosition = new THREE.Vector3();    
    
        targetPosition.copy(this.lookAtPoint);
        meshPosition.copy(this.mesh.position);
        const direction = new THREE.Vector3().subVectors(targetPosition, meshPosition).normalize();
        const targetQuaternion = new THREE.Quaternion();
        targetQuaternion.setFromUnitVectors(new THREE.Vector3(0, 0, 1), direction); 
        this.mesh.quaternion.slerp(targetQuaternion, 0.025);
    }



}

export default Paper;
