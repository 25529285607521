import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CSSGlobalVars from '../../../utils/CSSGlobalVars';

const MessageContainer = styled.p.attrs(({ $isVisible, $left, $top, $isHuman }) => ({
  style: {
    left: `${$left}px`, 
    top: `${$top}px`, 
    color: $isHuman ? 'red' : CSSGlobalVars.primaryColor 
  },
}))`
  position: absolute;
  text-align: left;
  z-index: 1;
  max-width: 30%;
  font-size: 10px;
  font-family: IBM Plex Mono, monospace;
  transition: opacity 0.5s ease;
  pointer-events: none;  
  opacity: 1;
`;


const TypingMessage = ({ text, writeTime, stayTime, containerWidth = 500, containerHeight = 500, isHuman, onFinish }) => {
  const [displayedText, setDisplayedText] = useState(''); // Current "typed" text
  const [visible, setVisible] = useState(true); // Visibility state (fades out when false)
  const [position, setPosition] = useState({ left: 0, top: 0 }); // Randomized position

  useEffect(() => {
    // Randomize position within container
    const randomLeft = Math.random() * (containerWidth - 100);
    const randomTop = Math.random() * (containerHeight - 30);
    setPosition({ left: randomLeft, top: randomTop });

    let typingTimeout;
    let stayTimeout;
    let untypeTimeout;

    // Function to simulate typing effect
    const typeMessage = (index) => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index]);
        typingTimeout = setTimeout(() => typeMessage(index + 1), writeTime / text.length);
      } else {
        // After fully typed, wait for the stayTime, then start untyping
        stayTimeout = setTimeout(() => untypeMessage(text.length - 1), stayTime);
      }
    };

    // Function to simulate "untyping" effect
    const untypeMessage = (index) => {
      if (index >= 0) {
        setDisplayedText((prev) => prev.slice(0, index));
        untypeTimeout = setTimeout(() => untypeMessage(index - 1), writeTime / text.length);
      } else {
        // After untyping, trigger the onFinish callback to remove the component
        setVisible(false);
        setTimeout(onFinish, 500); // Delay for opacity transition
      }
    };

    // Start typing the message
    typeMessage(0);

    // Cleanup timeouts when component unmounts
    return () => {
      clearTimeout(typingTimeout);
      clearTimeout(stayTimeout);
      clearTimeout(untypeTimeout);
    };
  }, [text, writeTime, stayTime, containerWidth, containerHeight, onFinish]);

  return (
    <MessageContainer $isVisible={visible} $left={position.left} $top={position.top} $isHuman={isHuman}>
      {displayedText}
    </MessageContainer>
  );
};

export default TypingMessage;
