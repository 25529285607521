import WebContainer from './components/Web/WebContainer';
import Photo from './components/Photo/Photo'
import styled from 'styled-components';
import Admin from './components/Admin/Admin';
import { useEffect, useState } from 'react';

const AppStyle = styled.div`
  text-align: center;
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available; /* For fullscreen on mobile browsers */
  background: black;
  overflow: hidden; /* Prevents scrolling */
  background-color: #FEF3E9;
`

function App() {

  const [camArea,setCamArea]= useState('');


  useEffect(()=>{
    const params = new URLSearchParams(window.location.search);
    const pass = params.get('pass');

    setCamArea(pass)
  })

  const getContainer = ()=>{
    if(camArea === 'cam212603')
      return <Photo/>
    else if(camArea === 'admin212603')
      return <Admin/>
    else
      return <WebContainer/>
  }

  return (
    <AppStyle>
      {getContainer()}
    </AppStyle>
  );
}

export default App;