import styled from "styled-components";

const Wrapper = styled.div`

    width: 100%;
    height: 100px;

    background-color: #ffffff;

    display: grid;
    align-items: center;
    justify-items: left;

    p{  
        font-family: Tiposka;
        font-size: 10px;
        margin-left: 10%;
    }

`




const Footer = ()=>{

    return (
        <Wrapper>
            <p>© 2024 Pedro Garlaschi - All Rights Reserved. </p>
        </Wrapper>
    )

}


export default Footer;
