
import * as THREE from 'three';
import Paper from './Paper';
import {randomFloat} from '../../../utils/Utils';
import postcardBack from '../../../assets/images/back_frame.png'


export default class PaperFlocking{
    
    scene;
    numberOfPapers = 50;
    papers = [];
    postcards;
    currentPostcard = -1;

    constructor(scene){

        this.scene = scene;
    }

    async init(){
        const len = 100;
        const textureLoader = new THREE.TextureLoader();
        const back = await textureLoader.load(postcardBack);
        for(let i = 0 ; i < len;i++){
            this.createPaper(randomFloat(0,360) , "https://storage.googleapis.com/with-love-from-ai.appspot.com/1728320676821_ab8nsz1kxlm1z9i95i.jpg" , i, back);
            
        }
    }


    flip (){
        const len = this.papers.length;
        for(let i = 0 ; i < len;i++){
            
            if(this.papers[i].selected)
                this.papers[i].flip();
        }
    }

    setCurrentPostcad = (cPostcard)=>{

        this.currentPostcard = cPostcard;
        const len = this.papers.length;
        for(let i = 0 ; i < len;i++){
            

            if(cPostcard === i)
                this.papers[i].select(true)
            else
                this.papers[i].select(false)
        }

    }

    createPostcards =async (postcards)=>{
        const textureLoader = new THREE.TextureLoader();

        const back = await textureLoader.load(postcardBack);

        this.postcards = postcards;

        const len = postcards.length;

        let angle = 0;
        let add = 360 / len;
        for(let i = 0 ; i < len;i++){
            const p = postcards[i];
            angle += add;
            this.createPaper(angle , p.photoUrl , i,back);
        }
    }

    createPaper = (angle , image , i , back)=>{

        const radius = randomFloat(8,15.0);
        const y = randomFloat(-5,5);
        
        const lookAtPoint = new THREE.Vector3(0,y,0);

        const speed = randomFloat(0.1,0.5);

        const paper = new Paper(new THREE.Vector3(0,y,0),speed,radius,angle,this.scene , lookAtPoint,image , postcardBack , i  , back);
        this.papers.push(paper);


    }

    update = (deltaTime)=>{
        
        const len = this.papers.length;
        for(let i  = 0 ;i < len;i++){
            this.papers[i].update(deltaTime);
        }

    }

}